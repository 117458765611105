var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { managementService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from '~/core/decorator';
import { Service } from '~/core/service';
var TemplateDataService = /** @class */ (function (_super) {
    __extends(TemplateDataService, _super);
    function TemplateDataService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /*
     *新增模板
     */
    TemplateDataService.prototype.createTemplateData = function (data, loading) {
        return this.netService.send({
            server: managementService.templateDataController.createTemplateData,
            data: {
                dataStartColNum: data.dataStartColNum,
                dataStartRowNum: data.dataStartRowNum,
                principalName: data.principalName,
                templateName: data.templateName,
                titleStartColNum: data.titleStartColNum,
                titleStartRowNum: data.titleStartRowNum,
                templateExcelInfoList: data.templateExcelInfoList,
            },
            loading: loading
        });
    };
    /*
     *修改模板
     */
    TemplateDataService.prototype.templateDataModify = function (data, loading) {
        return this.netService.send({
            server: managementService.templateDataController.templateDataModify,
            data: {
                cellName: data.cellName,
                cellNum: data.cellNum,
                cellType: data.cellType,
                cellTypeName: data.cellTypeName,
                colNum: data.colNum,
                flag: data.flag,
                relateChineseName: data.relateChineseName,
                relateName: data.relateName,
                remark: data.remark,
            },
            loading: loading
        });
    };
    /**
     * 删除模板
     */
    TemplateDataService.prototype.deleteTemplateData = function (id, loading) {
        return this.netService.send({
            server: managementService.templateDataController.deleteTemplateData,
            append: [id],
            loading: loading
        });
    };
    /**
     * 获取模板详情
     * @param id 模板Id
     */
    TemplateDataService.prototype.getTemplateData = function (id, loading) {
        return this.netService.send({
            server: managementService.templateDataController.getTemplateData,
            append: [id],
            loading: loading
        });
    };
    /**
     * 委托方查询
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序方式
     */
    TemplateDataService.prototype.templateDataQuery = function (data, page, sort, loading) {
        return this.netService.send({
            server: managementService.templateDataController.templateDataQuery,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 解析Excel数据模板头信息
     * @param data
     */
    TemplateDataService.prototype.parserTemplateHeader = function (data, loading) {
        return this.netService.send({
            server: managementService.templateDataController.parserTemplateHeader,
            data: {
                fileId: data.fileId,
                sheetTotals: data.sheetTotals,
                titleStartCol: data.titleStartCol,
                titleStartRow: data.titleStartRow,
            },
            loading: loading
        });
    };
    /**
     * Excel数据导入模板
     * @param data
     */
    TemplateDataService.prototype.createImportExcelConfig = function (data, loading) {
        return this.netService.send({
            server: managementService.templateDataController.createImportExcelConfig,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改模板配置
     * @param data
     */
    TemplateDataService.prototype.importExcelConfigModify = function (data, loading) {
        return this.netService.send({
            server: managementService.templateDataController.importExcelConfigModify,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], TemplateDataService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], TemplateDataService.prototype, "createTemplateData", null);
    __decorate([
        Debounce()
    ], TemplateDataService.prototype, "templateDataModify", null);
    __decorate([
        Debounce()
    ], TemplateDataService.prototype, "deleteTemplateData", null);
    __decorate([
        Debounce()
    ], TemplateDataService.prototype, "parserTemplateHeader", null);
    __decorate([
        Debounce()
    ], TemplateDataService.prototype, "createImportExcelConfig", null);
    __decorate([
        Debounce()
    ], TemplateDataService.prototype, "importExcelConfigModify", null);
    return TemplateDataService;
}(Service));
export { TemplateDataService };
